import React from 'react';
import {
  ComponentPreview,
  ComponentWithLabel,
  DontDo,
  DontDoItem,
  Link,
  List,
  PageHero,
  PageNavigation,
  PageNavigationLink,
  PageWithSubNav,
  Paragraph,
  PlatformTable,
  PlatformTableRow,
  Section,
  SectionSubhead,
} from 'docComponents';
import { AvatarTeam, Link as UniLink, Text } from 'hudl-uniform-ui-components';
import linkPreviewData from '../../../data/previews/link.yml';
import pageHeroData from '../../../data/pages/components.yml';

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      linkType: 'default',
    };
  }

  onSwitch = value =>
    this.setState({
      linkType: value,
    });

  render() {
    return (
      <PageWithSubNav
        pageType="design"
        title="Link"
        designCode
        subnav="components">
        <PageHero heroData={pageHeroData} tierTwo="Link" />

        <PageNavigation>
          <PageNavigationLink>Type</PageNavigationLink>
          <PageNavigationLink>State</PageNavigationLink>
          <PageNavigationLink>Usage</PageNavigationLink>
          <PageNavigationLink>Microcopy</PageNavigationLink>
          <PageNavigationLink>Platform</PageNavigationLink>
        </PageNavigation>

        <Section title="Type">
          <Paragraph>
            There are three types of links: default, article and implied.
          </Paragraph>
          <ComponentPreview
            name="linkState"
            layout="split"
            previewData={linkPreviewData.types}
            onChange={this.onSwitch}>
            {this.state.linkType === 'default' && (
              <Text className="uni-pad--one--btm" ignoreThisComponent>
                <UniLink type="default">Pay your bill</UniLink> anytime, or call
                our billing team with questions.
              </Text>
            )}
            {this.state.linkType === 'article' && (
              <Text className="uni-pad--one--btm" ignoreThisComponent>
                To share video with individuals not on your
                <br />
                team, try an <UniLink type="article">Exchange</UniLink>,{' '}
                <UniLink type="article">download</UniLink>, or
                <br />
                <UniLink type="article">shareable link</UniLink>.
              </Text>
            )}
            {this.state.linkType === 'implied' && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <AvatarTeam sport="football" />
                <div className="uni-pad--half--left">
                  <UniLink type="implied">Football Highlights</UniLink>
                  <Text level="micro" className="uni-pad--quarter--top">
                    May 2nd at 4:19 PM
                  </Text>
                </div>
              </div>
            )}
            {this.state.linkType === 'button' && (
              <UniLink type="button">View Schedule</UniLink>
            )}
          </ComponentPreview>
        </Section>

        <Section title="State">
          <Paragraph>
            Links exist in a normal, hovered or active state.
          </Paragraph>
          <ComponentPreview name="linkState" layout="split">
            <ComponentWithLabel>
              <Text>
                <UniLink>Share this video</UniLink> with a message.
              </Text>
            </ComponentWithLabel>
          </ComponentPreview>
        </Section>

        <Section title="Usage">
          <SectionSubhead>Inheriting Text Color</SectionSubhead>
          <Paragraph>
            Default and implied links inherit their text color from the context
            in which they’re placed (e.g., paragraph, list item). To ensure your
            link complies with Uniform guidelines, use one of our{' '}
            <Link href="/components/type" isDesignCodeLink>
              type elements
            </Link>
            .
          </Paragraph>

          <SectionSubhead>Links vs. Buttons</SectionSubhead>
          <Paragraph>
            Links should send the user outside of the current context. To keep
            the user in the same context, use a{' '}
            <Link href="/components/buttons/button" isDesignCodeLink>
              button
            </Link>{' '}
            instead.
          </Paragraph>
          <Paragraph>
            If you’re worried about the visual weight of a button, try the{' '}
            <Link href="/components/buttons/button/design#Style">
              minimal style
            </Link>
            .
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="use a link to initiate an in-page action."
              img="link-linksvsbuttons-dont"
            />
            <DontDoItem
              type="do"
              text="use a link to take the user to another page."
              img="link-linksvsbuttons-do"
            />
          </DontDo>

          <Paragraph>
            One exception: Buttons may replace links in landing pages and in-app
            promotions where the{' '}
            <strong>primary call-to-action will take the user elsewhere</strong>{' '}
            (e.g., promoting a trial sign-up in the mobile app).
          </Paragraph>
          <Paragraph>
            Just remember to limit the number of links and actions on a single
            page, especially primary buttons.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="force yourself to use one massive link for a primary CTA."
              img="link-buttonaction-dont"
            />
            <DontDoItem
              type="do"
              text="use a button in exceptional cases to take the user to a new flow."
              img="link-buttonaction-do"
            />
          </DontDo>

          <SectionSubhead>Implied Type</SectionSubhead>
          <Paragraph>
            If one screen features dozens of links, try the implied style.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="overwhelm the user with default or article links."
              img="link-impliedtype-dont"
            />
            <DontDoItem
              type="do"
              text="use the implied type when natural exploration reveals interactivity."
              img="link-impliedtype-do"
            />
          </DontDo>
        </Section>

        <Section title="Microcopy">
          <Paragraph>
            Pay special attention to the words you wrap. The following
            guidelines can help.
          </Paragraph>

          <SectionSubhead>Avoid “Click Here”</SectionSubhead>
          <Paragraph>
            There are better words to link. Think of the children!
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="cut corners by adding “click here” to your phrase."
              img="link-avoidclickhere-dont"
            />
            <DontDoItem
              type="do"
              text="wrap your link around a verb and its object—ideally both."
              img="link-avoidclickhere-do"
            />
          </DontDo>

          <SectionSubhead>Link Phrases, Not Sentences</SectionSubhead>
          <Paragraph>Links need not overpower the text.</Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="link entire sentences."
              img="link-linkphrases-dont"
            />
            <DontDoItem
              type="do"
              text="draw attention to the area where a user can take action."
              img="link-linkphrases-do"
            />
          </DontDo>

          <SectionSubhead>
            Send Users <em>Somewhere Else</em>
          </SectionSubhead>
          <Paragraph>
            Ideally, your link text should describe or allude to a new
            destination.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="use links to keep users in the current context."
              img="link-somewhereelse-dont"
            />
            <DontDoItem
              type="do"
              text="focus your link text on that destination."
              img="link-somewhereelse-do"
            />
          </DontDo>

          <SectionSubhead>Balance the Findability</SectionSubhead>
          <Paragraph>
            If your link text is short, but the surrounding context is noisy,
            reconsider the style or rewrite the link to make it more noticeable.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="bury a link in a sea of microcopy (e.g., linking one small word)."
              img="link-findability-dont"
            />
            <DontDoItem
              type="do"
              text="ensure the link stands out."
              img="link-findability-do"
            />
          </DontDo>
        </Section>

        <Section title="Platform">
          <PlatformTable footerMessage="No guidelines exist for Windows (yet).">
            <PlatformTableRow platform="web" />
            <PlatformTableRow platform="apple">
              <List>
                <li>
                  Avoid the use of Links except for actions and destinations
                  that launch Safari to take you out of the Hudl app.
                </li>
              </List>
            </PlatformTableRow>
            <PlatformTableRow platform="android">
              <List>
                <li>
                  Avoid the use of Links except for actions and destinations
                  that launch Chrome to take you out of the Hudl app.
                </li>
              </List>
            </PlatformTableRow>
          </PlatformTable>
        </Section>
      </PageWithSubNav>
    );
  }
}
